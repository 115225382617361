@use '../../../styles/utils' as util;

.text_styles {
  font-style: normal;
  font-family: var(--font-play);
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #939BC2;
}

.display_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.display_column {
  display: flex;
  flex-direction: column;
}

.container {
  @extend .display_row;
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  background: #191f40;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;

  @media (width >= 1000px) {
    flex-wrap: wrap;
  }
  @media (width >= 1440px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.container_filters {
  @extend .display_row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media (width >= 1000px) {
    flex-direction: row;

    &.container_filters_bottom {
      flex-direction: column;
    }
  }
  @media (width >= 1440px) {
    width: auto;

    &.container_filters_top {
      order: 2;
    }

    &.container_filters_bottom {
      flex-direction: row;
    }
  }
}

.container_filters_bottom_content {
  @extend .display_row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 16px;

  @media (width >= 1000px) {
    flex-direction: row;
  }
  @media (width >= 1440px) {
    width: auto;
    margin-top: 0;
    min-width: 1000px;
  }
}

.container_filters_title {
  @extend .display_row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
}

.container_filters_title_text {
  @extend .display_row;
  border-radius: 32px;
  gap: 4px;
  padding-top: 12px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-left: 16px;
  background: #23294A;
  font-family: var(--font-play);
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #939BC2;
  transition: color 0.3s ease;

  &.active {
    color: #D3F85A;
  }

  @media (min-width: 1440px) {
    display: none;
  }
}

.container_filters_enough_money {
  @extend .display_row;
  @extend .text_styles;
  gap: 8px;
}

.container_filters_autocomplete {
  width: 100%;
  height: 48px;
  max-width: 100%;

  @media (width >= 1000px) {
    @include util.AdaptiveSize(max-width, 303px, 392px, 375px, 1440px);
  }
}

.container_filters_main_price {
  position: relative;
  @extend .display_column;
  padding: 15px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(147, 155, 194, 0.32);
  background: #252e5c;
  font-family: var(--font-play);
  transition: all 0.3s ease;
  
  &:hover {
    border: 1px solid rgba(147, 155, 194, 0.64);
    transition: border 0.5s;
  }

  &.container_filters_main_price_highlighted {
    animation: highlightPrice 1s ease-in-out;
    border-color: var(--primary-color);
    box-shadow: 0 0 15px rgba(211, 248, 90, 0.2);
  }
}

@keyframes highlightPrice {
  0% {
    border-color: rgba(147, 155, 194, 0.32);
    box-shadow: none;
  }
  50% {
    border-color: var(--primary-color);
    box-shadow: 0 0 20px rgba(211, 248, 90, 0.3);
  }
  100% {
    border-color: rgba(147, 155, 194, 0.32);
    box-shadow: none;
  }
}

.container_filters_main_price_input {
  text-align: right;
}

.container_filters_main_price_separator {
  color: #ffffff
}

.container_filters_main_price_title {
  @extend .text_styles;
  text-align: left;
  width: 100%;

  @media (width >= 1000px) {
    display: none;
  }
}

.container_filters_main_price_container {
  @extend .display_row;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.container_filters_main_price_text {
  margin-left: 2px;
  color: white;
}

.container_filters_main_price_slider {
  width: 100%;
  padding-inline: 5px;
  display: flex
}

.container_filters_main_price_slider_line {
  color: var(--primary-color);
  padding: 0;

  & .MuiSlider-rail {
    background: #252E5C;
    background: #252E5C !important;
    opacity: 1 !important;
    opacity: 1;
  }

  & .MuiSlider-track {
    background: #252E5C;
    opacity: 1;
  }

  & .MuiSlider-thumb {
    width: 24px;
    height: 24px;
  }
}

.container_filters_resetBtn {
  width: 100%;

  @media (width >= 1440px) {
    width: fit-content;
  }
}

.container_resetBtn {
  @extend .display_row;
  width: fit-content;
  gap: 8px;
  cursor: pointer;
  padding: 0;
}

.container_resetBtn_text {
  @extend .text_styles;
  color: #bdc2dc;
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.48px;
}

.container_filters_bottom {
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;

  @media (min-width: 1440px) {
    max-height: none;
    opacity: 1;
    visibility: visible;
  }
}

.container_filters_bottom_visible {
  max-height: 1000px;
  opacity: 1;
  visibility: visible;
}
