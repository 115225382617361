.main_container {
  display: flex;
  flex-direction: column;
  margin: 241px 0 64px 0;
  @media (max-width: 700px) {
    margin: 192px 0 0 0;
  }
}

.title {
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: 52px;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 0 32px rgba(255, 255, 255, 0.28);

  @media (max-width: 700px) {
    font-size: 28px;
  }
}

.content_container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 64px;
  animation: ani 3s ease forwards;
  position: relative;

  @media (max-width: 1100px) {
    margin: 20px 12px;
  }

  @keyframes ani {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
.content {
  display: flex;
  font-size: 18px;
  line-height: 1.33;
  font-weight: 400;
  color: #9297b2;
  cursor: pointer;
}

.shadow {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  height: 100%;
  width: 101%;
  background: linear-gradient(180deg, rgba(12, 16, 39, 0) 0%, #0c1027 69.05%);
  pointer-events: none;
}
.mainContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-grow: 1;
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 16px;
    // white-space: nowrap;
    justify-content: center;
  }
}

.openBlock {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // height: 100px;
  // width: 100px;
  animation: ani 2.5s forwards;

  @keyframes ani {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.readMore {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 400;
  font-family: var(--font-inter);
  text-shadow: 0 0 24px rgba(211, 248, 90, 0.32);
  color: var(--primary-color);
  cursor: pointer;
}

.mapBlock {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  // max-width: 24%;
  // min-width: 24%;
}

.numberBg {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
  font-family: var(--font-arame);
  height: 48px;
  min-width: 48px;
  border: solid 2px transparent;
  background-image: linear-gradient(#0c1027, #0c1027),
    linear-gradient(165deg, #ffffff 0%, #ffffff24 60%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-radius: 8px;
  cursor: pointer;
}

.line {
  width: 100%;
  border-bottom: 1px solid #303863;
  margin-bottom: 40px;
}

.titleText {
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 28px;
  line-height: 1.3;
  color: #ffffff;
  text-shadow: 0 0 32px rgba(255, 255, 255, 0.28);

  @media (max-width: 1100px) {
    font-size: 20px;
    padding-bottom: 24px;
  }
}

.text {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
  color: #9297b3;
  padding-bottom: 60px;

  @media (max-width: 1100px) {
    font-size: 14px;
    padding-bottom: 40px;
  }
}

.textList {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
  color: #9297b3;
  padding-bottom: 15px;

  @media (max-width: 1100px) {
    font-size: 14px;
    padding-bottom: 5px;
  }
}

.grid_container {
  display: flex;
  gap: 40px;

  @media (max-width: 700px) {
    gap: 16px;
  }
}

.grid {
  display: flex;
  justify-content: flex-start;
  width: 30%;

  @media (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }
}

.ol {
  margin-left: 50px;

  @media (max-width: 1100px) {
    margin-left: 0;
  }
}

.faqQuestions {
  font-size: 23px;
  font-weight: 400;
  line-height: 1.44;
  color: #9297b3;
  padding-bottom: 8px;

  @media (max-width: 1100px) {
    font-size: 19px;
    padding-bottom: 4px;
  }
}

.faqAnswer {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
  color: #9297b3;
  padding-bottom: 30px;

  @media (max-width: 1100px) {
    font-size: 14px;
    padding-bottom: 25px;
  }
}
